import React, { useState } from "react"

import { graphql } from "gatsby"
import ElevatorContent from "../components/ElevatorContent"
import ElevatorHero from "../components/ElevatorHero"
import { MultiPageFormProvider } from "../components/ContextProviders/MultiPageFormProvider"
import ElevatorForm from "../components/elevatorInquiry/ElevatorForm"
import Layout from "../components/layout"
import PageEndBanner from "../components/PageEndBanner"
import Seo from "../components/seo"

const Modernization = ({ pageContext, data: { modern, miscString } }) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout pageData={pageContext}>
      <Seo
        title={modern.title}
        seoTitle={modern.seo?.seoTitle}
        seoDescription={modern.seo?.seoDescription}
        seoImage={modern.seo?.image?.seoImageUrl}
      />
      <ElevatorHero
        isHero
        gradient={true}
        description={modern.description}
        tag={modern.tag}
        title={modern.title}
        cta={miscString.nodes[0]?.getInquiry}
        open={open}
        setOpen={() => setOpen(!open)}
        //heroimage={modern.hero.gatsbyImageData}
        bottomImage
        isDark
      />
      <MultiPageFormProvider>
        <ElevatorForm open={open} setOpen={() => setOpen(false)} />
      </MultiPageFormProvider>
      {modern.content?.value && (
        <ElevatorContent structuredData={modern.content} />
      )}

      <PageEndBanner onClick={() => setOpen(!open)} />
    </Layout>
  )
}

export default Modernization

export const query = graphql`
  query modernQuery($id: String, $locale: String) {
    modern: datoCmsModernization(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      locale
      slug
      description
      tag
      hero {
        gatsbyImageData(height: 400)
        url
      }
      content {
        value
        links {
          __typename
          id: originalId

          title
          slug
        }
        blocks {
          ... on DatoCmsTwoColumnContentImage {
            id: originalId

            __typename

            title
            specs {
              specs
            }
            image {
              url
              gatsbyImageData(height: 350)
            }
            content {
              value
            }
            cta
            link {
              ... on DatoCmsCabinPage {
                id: originalId
              }
              ... on DatoCmsElectronicPage {
                id: originalId
              }
              ... on DatoCmsElectronic {
                id: originalId
              }
            }
          }
          ... on DatoCmsModernChoice {
            id: originalId

            __typename
            description
            structuredDescription {
              value
              links {
                ... on DatoCmsElectronic {
                  __typename
                  id: originalId

                  title
                  slug
                }
                ... on DatoCmsController {
                  __typename
                  id: originalId
                  title

                  slug
                }
              }
            }

            beforeAfter {
              url
            }
            choice {
              gatsbyImageData(height: 350)
              url
              title
            }
            title
          }
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }

    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
      }
    }
  }
`
